<template>
  <v-container>
    <v-row>
      <!-- Left Space for Administrator Information and Actions -->
      <v-col cols="12" md="8">
        <v-container>
          <h1>Administratorweb</h1>
          <p>Her kan du administrere Gture sine vinlotterier.</p>
          <v-btn color="primary" class="mr-2" @click="createDialog = true">Opprett nytt vinlotteri</v-btn>
          <v-btn color="secondary" @click="startTrekning">Start trekning</v-btn>
        </v-container>
      </v-col>
    
      <!-- Right Space for Vinlotterier List -->
      <v-col cols="12" md="4">
        <v-container>
          <h2>Alle vinlotterier</h2>
          <v-list dense class="rounded-list">
            <v-list-item
              v-for="(lottery, index) in lotteries"
              :key="lottery._id"
              :title="lottery.theme"
              :subtitle="formatDate(lottery.date)"
            >
              <template v-slot:append>
                <v-chip v-if="index === 0" color="success" size="small" class="mr-2">
                  Aktivt
                </v-chip>
                <v-btn
                  color="primary"
                  size="small"
                  class="mr-2"
                  @click="viewLotteryDetails(lottery._id)"
                >
                  Detaljer
                </v-btn>
                <v-btn
                  color="info"
                  size="small"
                  @click="viewTickets(lottery._id)"
                >
                  Se lodd
                </v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-container>
      </v-col>
    </v-row>

    <!-- Create Dialog -->
    <v-dialog v-model="createDialog" max-width="500px">
      <v-card>
        <v-card-title>Opprett nytt vinlotteri</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field 
                v-model="newLottery.theme" 
                label="Tema"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newLottery.lotteryDate"
                label="Dato"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createLottery">Opprett</v-btn>
          <v-btn color="error" @click="createDialog = false">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for messages -->
    <v-snackbar v-model="snackbar.show" :timeout="3000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getAccessToken } from './authService';
import config from '@/config';

export default {
  name: 'AdminPage',
  setup() {
    const router = useRouter();
    const lotteries = ref([]);
    const createDialog = ref(false);
    const snackbar = ref({ show: false, message: '' });
    const newLottery = ref({
      theme: '',
      lotteryDate: new Date().toISOString().slice(0, 10)
    });

    const showMessage = (message) => {
      snackbar.value.message = message;
      snackbar.value.show = true;
    };

    const checkIfAdmin = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${config.apiBaseUrl}/api/checkIfAdmin`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Not authorized');
        }

        const result = await response.json();
        if (!result.isAdmin) {
          showMessage('Bare admins kan komme hit, ikke prøv igjen');
          setTimeout(() => {
            router.push({ name: 'HomeVinlotteri' });
          }, 2000);
          return false;
        }
        return true;
      } catch (error) {
        console.error('Error checking admin status:', error);
        showMessage('Bare admins kan komme hit, ikke prøv igjen');
        setTimeout(() => {
          router.push({ name: 'HomeVinlotteri' });
        }, 2000);
        return false;
      }
    };

    const fetchLotteries = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${config.apiBaseUrl}/api/GetAllLotteries`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const sortedData = data.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        localStorage.setItem('allLotteries', JSON.stringify(sortedData));
        lotteries.value = sortedData;
      } catch (error) {
        console.error('Det skjedde en feil i uthenting av vinlotterier:', error);
      }
    };

    const createLottery = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${config.apiBaseUrl}/api/createLottery`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newLottery.value)
        });

        if (!response.ok) {
          throw new Error('Failed to create lottery');
        }

        await response.json();
        createDialog.value = false;
        showMessage('Lotteri opprettet');
        await fetchLotteries();
      } catch (error) {
        console.error('Error creating lottery:', error);
        showMessage('Kunne ikke opprette lotteri');
      }
    };

    const startTrekning = () => {
      router.push('/SpinningWheel');
    };

    const viewTickets = (lotteryId) => {
      router.push(`/lottery/${lotteryId}/tickets`);
    };

    const viewLotteryDetails = (lotteryId) => {
      router.push(`/lottery/${lotteryId}`);
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('no-NO');
    };

    onMounted(async () => {
      const isAdmin = await checkIfAdmin();
      if (isAdmin) {
        await fetchLotteries();
      }
    });

    return {
      lotteries,
      createDialog,
      newLottery,
      snackbar,
      createLottery,
      startTrekning,
      viewTickets,
      viewLotteryDetails,
      formatDate
    };
  }
};
</script>

<style scoped>
.rounded-list {
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(var(--v-theme-surface));
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-chip {
  font-size: 0.75rem;
  height: 20px;
}
</style>