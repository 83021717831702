import { createRouter, createWebHistory } from 'vue-router';
import BuySpecificLotteryNumber from '@/components/BuySpecificLotteryNumber'; // Import the new component
import HomeVinlotteri from '@/components/HomeVinlotteri.vue';
import AdminPage from '@/components/AdminPage.vue';
import SpinningWheel from '@/components/SpinningWheel';
import LoginPage from '@/components/LoginPage.vue';
import { isAuthenticated } from '@/components/authService';
import LoginTest from '@/components/LoginTest.vue';
import LotteryDetails from '@/components/LotteryDetails.vue'; // Import the new component
import TicketOverview from '@/components/TicketOverview.vue'


const routes = [
  {
    path: '/',
    name: 'HomeVinlotteri',
    component: HomeVinlotteri,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login-test',
    name: 'LoginTest',
    component: LoginTest,
  },

    {
        path: '/buy-specific-number', // Define the path for the new route
        name: 'BuySpecificLotteryNumber',
        component: BuySpecificLotteryNumber,
        meta: {
            requiresAuth: true,
        },
    }, 
    {
        path: '/AdminPage',
        name: 'Admin',
        component: AdminPage,
        meta: {
            requiresAuth: true,
        },
    },
  {
    path: '/SpinningWheel/:id',
    name: 'SpinningWheel',
    component: SpinningWheel,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/lottery/:id/tickets',
    name: 'TicketOverview',
    component: TicketOverview,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/lottery/:id',
    name: 'LotteryDetails',
    component: LotteryDetails,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(`Navigating from ${from.name} to ${to.name}`);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //const authenticated = await isAuthenticated();
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
