<template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Oversikt over lodd</h1>
          <v-btn color="primary" @click="$router.back()" class="mb-4">Tilbake</v-btn>
          
          <v-card v-if="ticketOwners">
            <v-card-text>
              <v-list>
                <v-list-item v-for="(tickets, owner) in ticketOwners" :key="owner">
                  <v-list-item-title class="font-weight-bold">{{ owner }}</v-list-item-title>
                  <v-list-item-subtitle>
                    Lodd: {{ tickets.join(', ') }}
                    <v-chip class="ml-2" color="primary" size="small">
                      {{ tickets.length }} lodd
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
  
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { defineComponent, ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { getAccessToken } from './authService';
  import config from '@/config';
  
  export default defineComponent({
    name: 'TicketOverview',
    setup() {
      const route = useRoute();
      const ticketOwners = ref(null);
      const loading = ref(true);
  
      const fetchTicketOwners = async () => {
        try {
          const token = await getAccessToken();
          const response = await fetch(
            `${config.apiBaseUrl}/api/getOwnersOfTickets?lotteryId=${route.params.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          ticketOwners.value = data;
        } catch (error) {
          console.error('Error fetching ticket owners:', error);
        } finally {
          loading.value = false;
        }
      };
  
      onMounted(() => {
        fetchTicketOwners();
      });
  
      return {
        ticketOwners,
        loading,
      };
    },
  });
  </script>